import React from 'react';
import { graphql } from 'gatsby';
import { ItemsListIndexPage, Link, List, PageWithSubNav, Paragraph, Section, SectionSubhead } from 'docComponents';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Overview" subnav="words">
      <Section>
        <Paragraph>
          We’re here to talk grammar, mechanics and everything you need to know about the Hudl voice. And to understand
          the Hudl voice, you have to understand our mission to <strong>help teams and athletes win</strong>.
        </Paragraph>
        <Paragraph>
          Those are the people we serve—coaches, athletes and the people supporting them. Everything we do, we do for
          them. That includes the products we build, the blogs we publish, the events we attend and more. None of it
          means anything if we can’t communicate.
        </Paragraph>
        <Paragraph>That’s why the Hudl voice is human, first and foremost.</Paragraph>
        <Paragraph>
          And sure, providing the guideline to “write like you speak” covers decent ground, but there are other prompts
          to make sure your words hit the Hudl mark.
        </Paragraph>
        <Paragraph>
          Our <strong>Content Principles</strong> exist to unite every bit of writing we produce, whether it’s a blog,
          an email or a company-wide Slack announcement.
        </Paragraph>
        <Paragraph>
          We kept the list short to make it easy to remember. So the next time you sit down to write or edit,
          double-check that you're hitting all three.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>We’re talking to real people.</SectionSubhead>

        <List>
          <li>Hudl may be all tech, but our users are not robots. Write the way you’d speak directly to them.</li>
          <li>
            Those users have emotions. Your tone should always match whatever they’d feel while reading the content.
          </li>
          <li>Keep content personally relevant.</li>
        </List>
      </Section>
      <Section>
        <SectionSubhead>Our users trust us.</SectionSubhead>

        <List>
          <li>
            Have confidence in what you’re writing. If there’s the slightest chance it could spark doubt in the reader’s
            mind, try again. (Remove “should”, “might”, and “hope”.)
          </li>
          <li>Gaining trust is a lot harder than losing it. Consider reactions above all else.</li>
          <li>Never disclose information they’d be surprised to see about themselves or another user.</li>
        </List>
      </Section>
      <Section>
        <SectionSubhead>Every sport is a game—have fun.</SectionSubhead>

        <List className="uni-pad--one--btm">
          <li>Our office doesn’t have the corporate vibe, neither should our writing.</li>
          <li>Informative, professional conversations can be upbeat. Just use the right words.</li>
          <li>Remind the user why they enjoy this sport, that it is fun, and we’re here to keep it that way.</li>
        </List>
      </Section>

      <Section>
        <ItemsListIndexPage data={data} tierTwo="writing-guidelines" listType="word" listLayout="2" />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query copyIndexQuery($tierOne: String = "Words") {
    ...indexList
  }
`;
